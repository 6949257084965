<template>
  <div>
    <div class="container-fluid">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'Disputes', 'link': $router.resolve({ name: 'admin_dispute_landing_page' }).href },
        { 'name': $t('dispute_' + this.action).toUpperCase() }
      ]"/>
      <div class="main-container">
        <h1 class="font-weight-bold">{{ $t('dispute_' + this.$route.params['action'].toLowerCase()) }}</h1>
        <div v-if="flushMessage">
          <b-alert variant="success" dismissible fade show>{{ flushMessage }}</b-alert>
        </div>

        <div class="content-box p-3">
          <p class="text-left">Search for disputes</p>
          <div class="d-flex">
            <b-input-group>
              <b-form-input v-model="searchInput" type="text" ref="searchInput" placeholder="Search email, username or order hash"/>
              <b-input-group-append>
                <b-button variant="primary" size="sm" type="submit" v-on:click="search = searchInput">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="ml-3">
              <select class="form-control" v-model="sortAdmin">
                <option value="" disabled="disabled">Sort By</option>
                <option selected="selected" value="email">Email</option>
                <option value="date_created">Date Created</option>
                <option value="username">Username</option>
                <option value="date_modified">Date Modified</option>
                <option value="date_last_response">Date of Last Response</option>
              </select>
            </div>
          </div>
        </div>

        <AdminDisputeTable :action="action" :search="search" :sort="sortAdmin" :display-warning="action !== 'complete'"
                           :title="action === 'outstanding' ? 'Disputes awaiting approval' : 'Disputes'"/>
        <AdminDisputeTable v-if="action === 'outstanding'" :action="action" :search="search" :sort="sortAdmin" :display-warning="true" :evidence="false"
                           title="Disputes awaiting evidence"/>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import AdminDisputeTable from '../../../components/Admin/Dispute/AdminDisputeTable'

export default {
  name: 'AdminDisputes',
  components: {
    Breadcrumb,
    AdminDisputeTable
  },
  props: {
    flushMessage: String
  },
  data () {
    return {
      action: this.$route.params.action,
      searchInput: '',
      sortAdmin: '',
      search: ''
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .border-alert {
  &.border-left-danger {
    border-left: 3px solid $brand-scarlet
  }

  &.border-left-warning {
    border-left: 3px solid $brand-tangerine
  }

  &.border-left-success {
    border-left: 3px solid $green
  }
}

ul {
  list-style: none;
  padding: 0;
}

li {
  float: left;
  margin-right: 20px;
}

.breadcrumbs a:link,
.breadcrumbs a:visited,
.breadcrumbs a:hover,
.breadcrumbs a:active {
  text-decoration: none;
  color: #888;
}

.breadcrumbs:hover,
.breadcrumbs a {
  cursor: default;
}

.breadcrumbs a.clickable:hover {
  cursor: pointer;
  color: #000 !important;
  text-decoration: underline !important;
}

.breadcrumbs {
  z-index: 3;
  color: #888 !important;
}

.breadcrumbs i {
  color: #888;
}

#search {
  width: 100% !important;
}
</style>
