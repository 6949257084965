<template>
  <div>
    <hr>
    <h4 class="text-center font-weight-bold">Review Check</h4>
    <div id="review_error" class="alert alert-danger" v-if="error">
      <div>Please accept or decline review </div>
    </div>
    <b-form-group
      id="checkboxes-dispute-payment"
    >
      <b-form-radio id="accept_review"   value="1">Accept Review</b-form-radio>
      <b-form-radio id="decline_review"  value="2">Decline Review</b-form-radio>
    </b-form-group>
    <p class="pt-2">By accepting you are agreeing to show review to customers</p>
  </div>
</template>

<script>
export default {
  name: 'ReviewForm',
  props: {
    error: Boolean
  }
}
</script>

<style scoped>

</style>
