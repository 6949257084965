<template>
  <div>
    <div class="container" v-if="pageLoaded">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': '/admin' },
        { 'name': 'Dispute', 'link': '/admin/dispute' },
        { 'name': 'Dispute ' + action , 'link': '/admin/dispute/' + action },
        { 'name': '#' + order.hash + ' / ' + order.items[0].gig_title  }
      ]"/>
      <div class="main-container pt-5">
        <div class="row">
          <div class="col-lg-9 col-md-12 mb-3">
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Order Details" active class="text-left">
                  <div class="row">
                    <div class="col-12">
                      <h4 class="text-left">Order</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <span>Order Reference</span>
                    </div>
                    <div class="col-9">
                      <span>#{{order.hash}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <span>Seller</span>
                    </div>
                    <div class="col-9">
                      <span>{{order.seller_username}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <span>Buyer</span>
                    </div>
                    <div class="col-9">
                      <span>{{order.buyer_username}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <span>Price</span>
                    </div>
                    <div class="col-9">
                      <span>${{order.price_total.toFixed(2)}} / {{order.price_etn.toFixed(2)}} ETN</span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <h4 class="text-left">Task Detail</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      Task name
                    </div>
                    <span class="col-9">
                      {{order.items[0].gig_title}}
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      Task Description
                    </div>
                    <div class="col-9" v-html="this.$sanitize(order.items[0].description)"></div>
                  </div>
                  <div v-if="review !== null">
                    <div class="row mt-3">
                      <div class="col-12">
                        <h4 class="text-left">Review</h4>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-3">
                        Star Rating
                      </div>
                      <span class="col-9">
                      <star-rating
                        v-model="review.rating"
                        v-bind:star-size="25"
                        v-bind:increment="1"
                        read-only="true"
                      />
                    </span>
                    </div>
                    <div class="row" v-if="review.title !== null">
                      <div class="col-3">
                        Review Title
                      </div>
                      <div class="col-9">{{review.title}}</div>
                    </div>
                    <div class="row">
                      <div class="col-3">
                        Review Summary
                      </div>
                      <div class="col-9" v-html="this.$sanitize(review.message)"></div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Requirments">
                  <Requirement :order="order" :requirements="order.requirements" :hash="order.hash" :customQuoteRequirements="customQuote ? order.items[0].description : false"/>
                </b-tab>
                <b-tab title="Delivery">
                  <Delivery :deliveries="order.order_asset"/>
                </b-tab>
                <b-tab title="Messages" @click="getOrderMessages()" lazy>
                  <MessageInbox :messages="orderMessages" :unreadMessage="[]" :username="order.seller_username" v-if="messageLoadedOrder" :showHeader="false" hash="null" :lock="true" :admin="true" :adminDisableInput="true" v-lazy-container="{ selector: 'img' }"/>
                  <div v-else class="mx-auto my-5">
                    <b-spinner large label="Large Spinner"/>
                    <div>Messages Loading</div>
                  </div>
                </b-tab>
                <b-tab title="Timeline">
                  <Timeline :timelines="timeline"/>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
          <div class="col-lg-3 mb-3 d-md-block ">
            <div class="side-details">
              <b-card-group deck>
                <b-card header-tag="header">
                  <h6 slot="header" class="mb-0 mt-0 py-2">Dispute Decision</h6>
                  <div v-if="disputeDecision" class="text-left">
                    <p>{{$t('disputes_decision_outcome', {outcome: $tc('dispute_response', dispute.status)}) }}</p>
                    <div class="alert alert-info" style="white-space: pre-line;">{{ dispute.reason }}</div>
                    <div v-if="this.dispute.status === 3">
                      <div class="col-12 mb-2">
                        <b-button v-if="!paymentLoading" v-on:click="showPaymentModal()" class="w-100" variant="primary">Process buyer refund</b-button>
                        <b-button v-else class="w-100" variant="primary">
                          <b-spinner small label="Small Spinner" />
                        </b-button>
                      </div>
                      <div class="align-center col-12">
                        <b-button v-on:click="showReasonModal('dispute-reason-modal', 'seller')" class="w-100 buttonWrap" variant="danger">No buyer refund, seller gets ETN</b-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{$t('dispute_admin_warning')}}</p>
                    <div class="row" v-if="!disputeSolutionSending">
                      <div class="col-12 mb-2">
                        <b-button v-on:click="showReasonModal('dispute-reason-modal', 'seller')" class="w-100" variant="primary">Seller receives ETN</b-button>
                      </div>
                      <div class="align-center col-12">
                        <b-button v-on:click="showReasonModal('dispute-reason-modal', 'buyer')" class="w-100" variant="danger">Refund buyer</b-button>
                      </div>
                    </div>
                    <b-button v-else variant="primary" disabled="disabled" block class="w-100">
                      <b-spinner small label="Small Spinner"/>
                    </b-button>
                  </div>
                </b-card>
              </b-card-group>
            </div>
          </div>
        </div>
        <div class="row dispute-message-container">
          <div class="col-lg-6 col-md-12 mt-3">
            <div class="side-details">
              <b-card-group deck>
                <b-card header-tag="header">
                  <h6 slot="header" class="mb-0 mt-0 py-2">Seller - {{order.seller_username}}</h6>
                  <div v-if="sellerMessageLoad">
                    <MessageInbox v-if="this.dispute.message_threads.length > 1 || sellerMessages.length > 0" :messages="sellerMessages" :unreadMessage="sellerMessagesUnread" :showHeader="false" :username="username" :hash="sellerMessagesHash"
                                  id="seller" :lock="sellerMessagesLock === '1'" :admin="true" :adminDisableInput="sellerMessagesLock === '1'" :disputePositionData="{'author' : order.seller_username, 'buyerColour' : false}" :preDefinedResponses="preDefinedResponse.seller"/>
                    <div v-else class="mx-auto no-messages pt-5">
                      <h5>{{$t('seller_submit_no_evidence')}}</h5>
                      <i class="mb-3 fa-regular fa-comments fa-4x" aria-hidden="true"/>
                    </div>
                  </div>
                  <div v-else class="mx-auto my-5">
                    <b-spinner large label="Large Spinner"/>
                    <div>Messages Loading</div>
                  </div>
                </b-card>
              </b-card-group>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 mt-3">
            <div class="side-details">
              <b-card-group deck>
                <b-card header-tag="header">
                  <h6 slot="header" class="mb-0 mt-0 py-2">Buyer - {{order.buyer_username}}</h6>
                  <div v-if="buyerMessageLoad">
                    <MessageInbox v-if="this.dispute.message_threads.length > 1 || buyerMessages.length > 0" :messages="buyerMessages" :showHeader="false" :unreadMessage="buyerMessagesUnread" :username="username" :hash="buyerMessagesHash" id="buyer"
                                  :lock="buyerMessagesLock === '1'" :admin="true" :adminDisableInput="buyerMessagesLock === '1'" :disputePositionData="{'author' : order.buyer_username, 'buyerColour' : true}" :preDefinedResponses="preDefinedResponse.buyer"/>
                    <div v-else class="mx-auto no-messages pt-5">
                      <h5>Buyer has not sent any evidence, please leave this dispute for a few days before making decision</h5>
                      <i class="mb-3 fa-regular fa-comments fa-4x" aria-hidden="true"/>
                    </div>
                  </div>
                  <div v-else class="mx-auto my-5">
                    <b-spinner large label="Large Spinner"/>
                    <div>Messages Loading</div>
                  </div>
                </b-card>
              </b-card-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"/>
      <div>{{ $t('loading') }}</div>
    </div>
    <dispute-modal id="dispute-reason-modal" :headerTitle="disputeData.header" :status="this.disputeData.status" :disputeNotes="this.disputeData.notes" :hash="this.order.hash" :redirect="this.action" :review="review !== null" />
  </div>
</template>

<script>
import config from '../../../config'
import Delivery from '../../../components/ReceiveTask/Delivery'
import Timeline from '../../../components/ReceiveTask/Timeline'
import ApiService from '../../../apiService.js'
import MessageInbox from '../../../components/MyAccount/Inbox/MessageInbox'
import Requirement from '../../../components/ReceiveTask/Requirement'
import DisputeModal from '../../../components/Admin/Dispute/AdminDisputeModal'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import StarRating from 'vue-star-rating'
import ReviewForm from '../../../components/Admin/Review/AdminReviewForm'

export default {
  name: 'AdminDispute',
  components: {
    Requirement,
    Timeline,
    Delivery,
    MessageInbox,
    DisputeModal,
    Breadcrumb,
    StarRating
  },
  data () {
    return {
      action: this.$route.params.action,
      disputeDecision: false,
      disputeSolutionSending: false,
      username: localStorage.getItem('username'),
      dispute: [],
      user: [],
      requirements: [],
      requirementsLoaded: false,
      order: [],
      orderMessages: [],
      sellerMessages: [],
      buyerMessages: [],
      sellerMessagesLock: false,
      buyerMessagesLock: false,
      sellerMessagesHash: '0',
      buyerMessagesHash: '0',
      sellerMessagesUnread: [],
      sellerMessageLoad: false,
      buyerMessageLoad: false,
      buyerMessagesUnread: [],
      messageLoadedOrder: false,
      messageThreadUsername: '',
      dueDate: '',
      timeline: [],
      review: null,
      pageLoaded: false,
      disputeData: {
        header: '',
        status: null,
        notes: ''
      },
      paymentLoading: false,
      customQuote: null,
      preDefinedResponse: {
        seller: [
          {
            'id': 'buyer_not_completed',
            'text': 'Sorry that the Buyer hasn\'t completed the order. The team are now checking that your Buyer is happy with your Task delivery and will process your ETN so that it\'s with you soon!'
          }, {
            'id': 'buyer_no_response',
            'text': 'Sorry that your Buyer has not responded. The team will take a look into it and if you have delivered the Task as requested you will be paid your ETN.'
          }, {
            'id': 'buyer_not_happy_with_completion',
            'text': 'We\'re sorry but your Buyer is not happy with the completed Task. The buyer will receive a refund on this basis.'
          }, {
            'id': 'etn_on_its_way',
            'text': 'You\'re ETN will be arriving with you in the next few days.'
          }
        ],
        buyer: [
          {
            'id': 'seller_no_response',
            'text': 'Sorry that your Seller hasn\'t responded to you. A refund will be processed straight away. In the meantime, please take a look at the other Sellers who\'d be more than happy to help. Your refund can take up to 10 days for the credit card company to process. '
          }, {
            'id': 'seller_late',
            'text': 'Sorry that the Seller hasn\'t met your deadline for delivery, you\'ll be refunded in full. Your refund can take up to 10 days for the credit card company to process. '
          }, {
            'id': 'seller_poor_quality',
            'text': 'Sorry that the quality of your Task doesn\'t meet with your expectations. You\'ll be refunded in full. Your refund can take up to 10 days for the credit card company to process. '
          }, {
            'id': 'buyer_more_info',
            'text': 'Please could you give some more information on why you want to cancel the order?'
          }, {
            'id': 'buyer_request_refund',
            'text': 'Your refund will be processed today. It may take the credit card company a few days to return this to you'
          }, {
            'id': 'seller_request_cancel',
            'text': 'Unfortunately your seller has asked us to cancel the order due to either not being able to deliver the task or not belong able to deliver in the required timescale. We will refund you in full.'
          }
        ]
      }
    }
  },
  mounted () {
    this.getDispute()
  },
  methods: {
    showReasonModal (id, selected) {
      if (selected === 'seller') {
        this.disputeData.header = this.$t('disputes_modal_title', { outcome: 'Seller receives ETN' })
        this.disputeData.status = 2
        this.disputeData.notes = 'Seller receives ETN, the buyer is not refunded.'
      } else {
        this.disputeData.header = this.$t('disputes_modal_title', { outcome: 'Refund buyer' })
        this.disputeData.status = 3
        this.disputeData.notes = 'Buyer is refunded, the seller does not receive ETN'
      }
      this.$bvModal.show(id)
    },
    showPaymentModal (error = false) {
      const h = this.$createElement
      let payments = []
      let paymentRef = []
      Object.entries(this.order.payments).forEach(([key, value]) => {
        if (value.payment_gateway.hash === 'stripe' && !paymentRef.includes(value.reference)) {
          paymentRef.push(value.reference)
          payments.push(
            h('div', { class: ['col-3'] }, 'Payment Type:'),
            h('div', { class: ['col-9'] }, 'Stripe payment'),
            h('div', { class: ['col-3'] }, 'Reference:'),
            h('div', { class: ['col-9'] }, [h('a', { attrs: { href: 'https://dashboard.stripe.com/payments/' + value.reference, target: '_blank' } }, value.reference)]),
            h('div', { class: ['col-12 pt-1'] }, [h('small', {}, 'Stripe refunds are now automatic, there is no need to visit Stripe unless there is an error')])
          )
        } else if (value.payment_gateway.hash === 'etn' && !paymentRef.includes(value.reference)) {
          if (value.notes !== null) {
            paymentRef.push(value.reference)
            payments.push(
              h('div', { class: ['col-3'] }, 'Payment Type:'),
              h('div', { class: ['col-9'] }, 'ETN'),
              h('div', { class: ['col-3'] }, 'Reference:'),
              h('div', { class: ['col-9'] }, [h('a', { attrs: { href: config.my_etn + '/vendor/payment/' + value.notes.ref, target: '_blank' } }, value.notes.ref)]),
              h('div', { class: ['col-3'] }, 'Payment ID:'),
              h('div', { class: ['col-9'] }, value.reference),
              h('div', { class: ['col-12 pt-1'] }, [h('small', {}, 'ETN refunds are not automatic, you will need to login and refund this order')])
            )
          }
        } else if (value.payment_gateway.hash !== 'stripe' && !paymentRef.includes(value.reference)) {
          paymentRef.push(value.reference)
          payments.push(
            h('div', { class: ['col-3'] }, 'Payment Type:'),
            h('div', { class: ['col-9'] }, value.payment_gateway.display_name),
            h('div', { class: ['col-3'] }, 'Reference:'),
            h('div', { class: ['col-9'] }, value.reference)
          )
        }
      })

      let display = [
        h('div', { class: ['row', 'p-2'] }, payments)
      ]

      if (this.review !== null) {
        display.push(
          h(ReviewForm, { props: { error: error } })
        )
      }

      const messageVNode = h('div', { class: ['container-fluid'] }, display)

      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: this.$t('disputes_modal_payment_title'),
        size: 'lg',
        hideHeaderClose: false,
        footerClass: 'p-2',
        okTitle: 'I confirm, I am giving the buyer a refund'
      }).then((value) => {
        this.paymentLoading = true
        if (value) {
          // this.$bvModalEvt.preventDefault()
          let data = { status: 1, reason: this.dispute.reason }
          if (this.review !== null) {
            if (document.getElementById('accept_review').checked) {
              data.review_status = 1
              this.patchDisputeEscalate(data)
            } else if (document.getElementById('decline_review').checked) {
              data.review_status = 2
              this.patchDisputeEscalate(data)
            } else {
              this.showPaymentModal(true)
              this.paymentLoading = false
            }
          } else {
            this.patchDisputeEscalate(data)
          }
        } else {
          this.paymentLoading = false
        }
      })
    },

    patchDisputeEscalate (data) {
      ApiService.disputePatch(this.order.hash, data).then(response => {
        this.$router.push({ name: 'admin_disputes', params: { action: this.action, 'flushMessage': this.$t('disputes_modal_success') + this.order.hash } })
      }).catch((response) => {
        let link = ''
        if (this.order.payments[0].payment_gateway.hash === 'stripe') {
          link = '<a href="https://dashboard.stripe.com/payments/' + this.order.payments[0].reference + '">' + this.order.payments[0].reference + '</a>'
        } else {
          link = this.order.payments[0].reference
        }

        this.$swal({
          type: 'error',
          title: 'Refund failed:',
          html: '<b>Error Message</b> <br>' + response.response.data.error_message + '<br> </br><b>Payment Reference</b> <br>' + link + '<br> </br><b>Payment Type</b><br>' + this.order.payments[0].payment_gateway.display_name
        })
        this.paymentLoading = false
      })
    },
    getDispute () {
      ApiService.getDispute(this.action, this.$route.params.hash).then(response => {
        this.dispute = response.data.data
        this.order = response.data.data.order
        this.user = response.data.data.user
        this.customQuote = this.order.custom
        this.getTimeLine()
        this.getReview()
        let vm = this
        this.dispute.message_threads.forEach(function (thread, index) {
          vm.getDisputeMessages(thread.hash, index)
        })

        if (this.dispute.status === 3) {
          this.disputeDecision = true
        } else if (this.dispute.status === 0) {
          this.disputeDecision = false
        } else {
          this.disputeDecision = true
          this.sellerMessagesLock = '1'
          this.buyerMessagesLock = '1'
        }

        this.pageLoaded = true
      }).catch(() => { })
    },
    getTimeLine () {
      ApiService.getOrderTimeline(this.order.hash).then(response => {
        this.timeline = response.data.data
      }).catch(() => { })
    },
    getReview () {
      ApiService.getReview(this.order.hash).then(response => {
        this.review = response.data.data
      }).catch(() => { })
    },
    getDisputeMessages (hash) {
      let messages = []
      let threadAuthor = ''

      this.dispute.message_threads.forEach(function (thread) {
        if (thread.hash === hash) {
          threadAuthor = thread.created_by.username
        }
      })

      if (threadAuthor === this.order.seller_username) {
        threadAuthor = 'seller'
        this.sellerMessageLoad = false
      } else if (threadAuthor === this.order.buyer_username) {
        threadAuthor = 'buyer'
        this.buyerMessageLoad = false
      }

      ApiService.getMessages(hash).then(response => {
        if (response.status !== 204) {
          let lastHash = ''
          response.data.data.forEach(function (message) {
            if (message.hash === lastHash) {
              message.repeat = true
            } else {
              message.repeat = false
              lastHash = message.hash
            }
          })
          messages = response.data.data
        }

        if (threadAuthor === 'seller') {
          this.sellerMessagesUnread = messages.filter(function (message) {
            return message.read < 1
          })
          this.sellerMessagesHash = hash
          this.sellerMessages = messages
          this.sellerMessageLoad = true
        }

        if (threadAuthor === 'buyer') {
          this.buyerMessagesUnread = messages.filter(function (message) {
            return message.read < 1
          })
          this.buyerMessagesHash = hash
          this.buyerMessages = messages
          this.buyerMessageLoad = true
        }

        if (this.dispute.message_threads.length < 2) {
          this.sellerMessageLoad = true
          this.buyerMessageLoad = true
        }
      }).catch(() => {
      })
    },
    getOrderMessages () {
      if (!this.messageLoadedOrder) {
        ApiService.getMessages(this.order.thread.hash).then(response => {
          this.orderMessages = response.data.data
          this.messageLoadedOrder = true
        }).catch(() => {
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .dispute-message-container {
    .lineThrough {
      background: #ffffff !important;
      padding: 0 10px;
    }
    .side-details .card {
      margin: 0 15px 0 15px !important;
    }
    .admin-colour {
      background-color: $brand-tangerine !important;
      &::after {
         border-left: 10px solid $brand-tangerine !important;
       }
    }
    .seller-colour {
      background-color: $brand-sky !important;
      &::after {
         border-right: 10px solid $brand-sky !important;
       }
    }
  }
</style>
<style scoped>
  .no-messages {
    color: lightslategray;
  }
  .buttonWrap{
    white-space: normal;
    word-wrap: break-word;
  }
  .container {
     max-width: 75%;
   }
</style>
