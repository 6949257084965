<template>
  <div class="content-box overflow-auto p-4">
    <template v-if="loaded & items.length > 0">
      <div class="d-flex justify-content-between mb-3 small">
        <span>{{ this.title }}</span>
        <span>Showing {{ ((page.currentPage - 1) * page.perPage) + 1 }} - {{ (page.currentPage * page.perPage) > page.total ? page.total : page.currentPage * page.perPage }} of {{ page.total }}</span>
      </div>
      <table class="table table-striped table-bordered table-hover add-manage-table">
        <thead>
          <tr>
            <th class="text-left">Order #</th>
            <th>Agreed</th>
            <th>Username</th>
            <th>Email</th>
            <th>Price</th>
            <th>Date Created</th>
            <th>Last Response</th>
            <th>
              <template v-if="action === 'complete'">Time Taken</template>
              <template v-else>Last Checked</template>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index" class="cursor-pointer" @click="$router.push('/admin/dispute/' + action + '/' + item.hash)">
            <td class="border-alert" v-bind:class="displayBorderWarning(item.date_created)">{{ item.order.hash }}</td>
            <td>{{ $tc('dispute_status', item.status) }}</td>
            <td>{{ item.user.username }}</td>
            <td>{{ item.user.email }}</td>
            <td>${{ item.order.price_total.toFixed(2) }}</td>
            <td>{{ item.date_created|formatDate }}</td>
            <td>{{ lastSeen(item.date_last_response) }}</td>
            <td>
              <template v-if="action === 'complete'">{{ timeTaken(item.date_created, item.date_modified) }}</template>
              <template v-else>{{ lastSeen(item.date_last_checked) }}</template>
            </td>
            <td class="text-right">
              <i class="fa-solid fa-magnifying-glass pr-2"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :perPage="page.perPage" :currentPage="page.currentPage" :total="page.total" v-on:paginationUpdate="updatePagination" class="mb-0" v-if="page.total > page.perPage"/>
    </template>
    <template v-else-if="loaded">
      <div>
        {{ $t('no_disputes_found_' + action) }}
      </div>
    </template>
    <div v-else>
      <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
      <div class="mb-5">Loading</div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../Global/Pagination'
import helper from '../../../helper'
import ApiService from '../../../apiService'

export default {
  name: 'AdminDisputeTable',
  components: {
    Pagination
  },
  props: {
    displayWarning: Boolean,
    title: String,
    action: String,
    sort: String,
    search: String,
    evidence: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    'sort': function (val) {
      this.sort = val
      this.getDispute()
    },
    'search': function (val) {
      this.search = val
      this.getDispute()
    }
  },
  data () {
    return {
      page: {
        perPage: 35,
        currentPage: 1,
        total: 0
      },
      items: [],
      loaded: false
    }
  },
  mounted () {
    this.getDispute()
  },
  methods: {
    getDispute () {
      this.loaded = false
      ApiService.adminDisputesSearch(this.action, this.page.perPage, this.page.currentPage, this.sort, this.search, this.evidence).then((response) => {
        this.items = response.data.data.results
        this.page.currentPage = response.data.data.pagination.page
        this.page.perPage = response.data.data.pagination.per_page
        this.page.total = response.data.data.pagination.total
        this.loaded = true
      }).catch((response) => {
        let error = response.response.data
        if (error.error_code === 'HTTP-403') {
          this.$router.push({ name: 'admin_dispute_landing_page', params: { 'flushMessage': { type: 'danger', message: error.error_message } } })
        }
      })
    },
    displayBorderWarning (date) {
      if (!this.displayWarning) {
        return ''
      }

      const days = helper.dateDiffInDays(date)
      if (days < -7) {
        return 'border-left-danger'
      }
      if (days < -3) {
        return 'border-left-warning'
      }
      return 'border-left-success'
    },
    updatePagination (page) {
      this.page.currentPage = page
      this.getDispute()
    },
    lastSeen: function (value) {
      if (!value) {
        return 'n/a'
      }
      return this.moment(value).fromNow()
    },
    timeTaken: function (start, finish) {
      return this.moment(start).from(finish, true)
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) {
        return 'n/a'
      }

      const newDate = new Date(value)
      if (newDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
        return new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    }
  }
}
</script>
<style>

</style>
