<template>
  <b-modal :id="id" size="lg" ref="modal" :title="headerTitle" @show="resetModal" @hidden="resetModal" header-class="mb-3" footer-class="border-0" hide-footer>
    <div class="mx-3 p-3">
      <h4 class="text-center font-weight-bold">{{ disputeNotes }}</h4>
      <div v-if="errors.length" class="alert alert-danger">
        <div v-for="error in errors" :key="error">{{ error }}</div>
      </div>
      <div class="mb-2 font-weight-bold">Reason</div>
      <div>
        <b-dropdown class="mb-3" text="Select a reason ....">
          <b-dropdown-item v-for="option in preDefinedResponse[status === 2 ? 'etn' : 'refund']" class="dropdown-options m-0 p-0" @click="updateReason(option.text)" :key="option.id">{{
              option.text
            }}
          </b-dropdown-item>
        </b-dropdown>
        <b-form-textarea
            id="textarea"
            v-model="formData.reason"
            placeholder="please enter a reason"
            rows="4"
            max-rows="6"
            class="m-0"
            required="required"
        ></b-form-textarea>
        <small>Between 5 and 191 chars</small>
      </div>
      <div v-if="review && status === 2">
        <hr>
        <h4 class="text-center font-weight-bold">Review Check</h4>
        <b-form-group
            id="checkboxes-dispute"
        >
          <b-form-radio v-model="review_status" value="1">Accept Review</b-form-radio>
          <b-form-radio v-model="review_status" value="2">Decline Review</b-form-radio>
        </b-form-group>
        <p class="pt-2">By accepting you are agreeing to show review to customers</p>
      </div>

      <div class="send pt-3" align="center">
        <b-button v-if="!submitted" variant="primary" @click="handleSubmit">Submit dispute decision</b-button>
        <b-button v-else variant="primary">
          <b-spinner class="" small label="Small Spinner"/>
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'disputeModal',
  props: {
    id: {
      type: String,
      required: false,
      default: 'dispute-modal'
    },
    headerTitle: String,
    status: Number,
    hash: String,
    redirect: String,
    disputeNotes: String,
    review: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      show: true,
      errors: [],
      submitted: false,
      review_status: null,
      formData: {
        reason: '',
        status: null
      },
      preDefinedResponse: {
        etn: [
          {
            'id': 'buyer_not_completed',
            'text': 'Task delivered as specified but the Buyer hasn\'t completed the order'
          }, {
            'id': 'buyer_no_response',
            'text': 'Buyer not responding'
          }, {
            'id': 'buyer_not_enough_detail',
            'text': 'Buyer has not provided enough detail to complete the Task'
          }, {
            'id': 'incorrect_delivery',
            'text': 'Buyer rejecting Task due to incorrect delivery'
          }, {
            'id': 'buyer_poor_quality',
            'text': 'Buyer rejecting Task due to poor quality delivery'
          }, {
            'id': 'buyer_requested',
            'text': 'Buyer requested to cancel order '
          }
        ],
        refund: [
          {
            'id': 'seller_no_response',
            'text': 'Seller not responding'
          }, {
            'id': 'seller_late',
            'text': 'The Seller has not delivered the Task in the agreed time'
          }, {
            'id': 'seller_not_delivered',
            'text': 'The Seller has not done as was asked / agreed'
          }, {
            'id': 'seller_poor_quality',
            'text': 'Unhappy with the quality of the Task delivered'
          }, {
            'id': 'seller_price',
            'text': 'Seller trying to negotiate price'
          }, {
            'id': 'seller_request_refund',
            'text': 'Seller requested to refund the Buyer'
          }, {
            'id': 'seller_off_platform',
            'text': 'Seller requested to communicate \'off platform\''
          }, {
            'id': 'seller_request_cancel',
            'text': 'Seller requested to cancel order'
          }
        ]
      }
    }
  },
  methods: {
    resetModal () {
      this.submitted = false
      this.errors = []
      this.formData = {
        reason: '',
        status: null
      }
    },
    checkFormValidity () {
      this.errors = []

      let valid = true

      if (this.formData.reason.length < 5) {
        this.errors.push('Please enter a min of 5 chars long')
        valid = false
      }

      if (this.formData.reason.length > 191) {
        this.errors.push('The max number of chars is 191')
        valid = false
      }

      if (this.review && this.review_status === null && this.status === 2) {
        this.errors.push('Please confirm if the review should go live or not')
        valid = false
      }

      return valid
    },
    handleSubmit () {
      this.submitted = true
      this.formData.status = this.status
      if (!this.checkFormValidity()) {
        this.submitted = false
        return
      }
      if (this.review !== null) {
        this.formData.review_status = this.review_status
      }
      ApiService.disputePatch(this.hash, this.formData).then(response => {
        this.$router.push({ name: 'admin_disputes', params: { action: this.redirect, 'flushMessage': this.$t('disputes_modal_success') + this.hash } })
      }).catch(() => {
        this.submitted = false
      })
    },
    updateReason (text) {
      this.formData.reason = text
    }
  }
}
</script>

<style scoped>
  .dropdown-options {
    list-style: none;
  }
</style>
