<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': $t('disputes').toUpperCase() }
      ]"/>
    <div class="main-container">
      <div v-if="this.flushMessage">
        <b-alert :variant="this.flushMessage.type" dismissible fade show>{{ this.flushMessage.message }}</b-alert>
      </div>

      <h1 class="mb-2 font-weight-bold">{{ $t('dispute') }}</h1>
      <div class="row mt-5 justify-content-center" v-if="this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_OUTSTANDING') || this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_ESCALATED') || this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_COMPLETE')">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_OUTSTANDING')">
          <Cards type="svg" image="fa-hourglass-start" :title="$t('dispute_outstanding')" :route="'/admin/dispute/outstanding'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 " v-if="this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_ESCALATED')">
          <Cards type="svg" image="fa-gavel" :title="$t('dispute_escalated')" :route="'/admin/dispute/escalated'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 " v-if="this.$store.getters.hasRole('ROLE_ADMIN_DISPUTE_COMPLETE')">
          <Cards type="svg" icon-type="fa-regular" image="fa-square-check" :title="$t('dispute_complete')" :route="'/admin/dispute/complete'"/>
        </div>
      </div>
      <div v-else>
        <b-alert variant="danger" fade show>{{$t('dispute_no_access')}}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import { mapActions } from 'vuex'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminDisputeLandingPage',
  components: {
    Breadcrumb,
    Cards
  },
  props: {
    flushMessage: {
      type: Object
    }
  },
  mounted () {
    this.buildUser()
  },
  methods: {
    ...mapActions([
      'buildUser'
    ])
  }
}
</script>

<style scoped>

</style>
